import React from 'react';
import { TextVariant } from '@naf/text';
import { CloudinaryImageType, CloudinaryVideoType } from '../../../../../types/blockTypes';
import ResponsiveImage from '../../../components/image/ResponsiveImage';
import { DynamicVideoPlayer } from '../../../components/video-player/DynamicVideoPlayer';
import { BenefitBulletsCard } from '../../../components/BenefitBulletsCard/BenefitBulletsCard';
import * as S from './Styles';

export interface BenefitHeaderProps {
  image?: CloudinaryImageType;
  video?: CloudinaryVideoType;
  title: string;
  ingress?: string;
  partner: {
    partnerName: string;
    logo: CloudinaryImageType;
  };
  keyInformation: {
    title: string;
    items: string[];
  };
}

export const BenefitHeader = ({ image, video, partner, title, ingress, keyInformation }: BenefitHeaderProps) => (
  <S.BenefitHeaderContainer>
    {image && !video && (
      <S.ImageWrapper>
        <S.ImageFigure>
          <ResponsiveImage imageId={image?.publicId} altText={image?.altText || image?.caption} />
        </S.ImageFigure>
        {partner?.logo && (
          <S.PartnerlogoWrapper>
            <img src={partner.logo.srcSet?.jpgLarge} alt={partner.logo?.source_altText || ''} />
          </S.PartnerlogoWrapper>
        )}
      </S.ImageWrapper>
    )}
    {video && (
      <S.ImageWrapper>
        <DynamicVideoPlayer video={video} />
      </S.ImageWrapper>
    )}
    <S.HeaderContentWrapper>
      <S.StyledHeaderText tag="h1" variant={TextVariant.Display}>
        {title}
      </S.StyledHeaderText>
      {keyInformation && (
        <S.RightContentWrapper>
          <BenefitBulletsCard title={keyInformation.title || 'Hva får du?'} bullets={keyInformation?.items} />
        </S.RightContentWrapper>
      )}
      {ingress && (
        <S.IngressWrapper>
          <S.Ingress tag="p" variant={TextVariant.Ingress}>
            {ingress}
          </S.Ingress>
          <S.HRLine />
        </S.IngressWrapper>
      )}
    </S.HeaderContentWrapper>
  </S.BenefitHeaderContainer>
);
