import React from 'react';
import { Button } from '@naf/button';
import { useHistory } from 'react-router';
import CtaActivateButton from './CtaActivateButton';
import { addUrlParam } from '../../lib/addUrlParams';
import * as S from './Styles';
import CtaButtonWithDiscountInfo from './CtaButtonWithDiscountInfo';
import { ExternalActivationHiddenForm } from '../MyMembership/ProductFlyout/ExternalActivationHiddenForm';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';
import { AccessDetails } from '../../../../types/benefitsType';

type ReferenceComponent = {
  id: string;
  type: string;
  slug?: string;
};

export type CTA = {
  buttonText: string;
  url: string;
  type: 'ExternalUrl' | 'ExternalEndpointActivate' | 'InternalLink' | 'InternalEndpointActivate';
  internalEndpoint?: string;
  internalLink?: string;
  disabled?: boolean;
  blank?: boolean;
  externalTransaction?: {
    activationUrl: string;
    activationMethod: 'POST' | 'GET' | 'PATCH' | 'PUT';
    headers: Record<string, string>;
    payload: Record<string, unknown>;
  };
  referenceComponent?: ReferenceComponent;
  ctaInternalLink?: {
    anchorId?: string;
    type: string;
    referenceComponent: {
      category?: {
        slug: string;
        type: string;
      };
      id: string;
      parent?: ReferenceComponent;
      type: string;
    };
  };
};

type Props = {
  callToAction: CTA;
  urlParameters?: Record<string, string>;
  onClickBecomeAMember: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  handleCtaBlankExternalTracking: (c: CTA) => any;
  accessDetails?: AccessDetails;
};

const CtaButton = ({
  callToAction,
  urlParameters,
  onClickBecomeAMember,
  handleCtaBlankExternalTracking,
  accessDetails,
}: Props) => {
  const ctaInternalUrl = useDocumentUrlWithNode(callToAction?.ctaInternalLink?.referenceComponent);
  const history = useHistory();

  const callToActionUrl =
    callToAction?.url && Object.keys(urlParameters).length > 0
      ? addUrlParam(urlParameters, callToAction.url)
      : callToAction?.url || null;

  const showBecomeMemberButton =
    accessDetails &&
    (accessDetails.membershipRole === 'Member' || !accessDetails.membershipRole) &&
    !accessDetails.isAccessibleForUser;

  if (
    !callToActionUrl &&
    callToAction?.type !== 'InternalLink' &&
    callToAction?.type !== 'InternalEndpointActivate' &&
    !showBecomeMemberButton
  )
    return null;

  const ctaRenderSwitch = (callToActionType: CTA['type']) => {
    switch (callToActionType) {
      case 'ExternalEndpointActivate':
        return (
          <ExternalActivationHiddenForm
            externalActivationDetails={callToAction.externalTransaction}
            buttonText={callToAction.buttonText}
            shouldHaveActivationButton
          />
        );
      case 'InternalLink':
        return (
          <S.StyledButton
            size="large"
            variant="signature"
            onClick={() => {
              if (ctaInternalUrl) {
                history.push(ctaInternalUrl);
              }
            }}
          >
            {callToAction.buttonText}
          </S.StyledButton>
        );
      case 'InternalEndpointActivate':
        // show button that calls the api, no link involved
        return <CtaActivateButton callToAction={callToAction} />;
      default:
        // show button that handles linking
        return (
          <Button
            onClick={handleCtaBlankExternalTracking(callToAction)}
            href={callToActionUrl}
            target={callToAction.blank ? '_blank' : '_self'}
            disabled={callToAction.disabled}
          >
            {callToAction.buttonText}
          </Button>
        );
    }
  };

  return !showBecomeMemberButton ? (
    <S.CTAWrapper>{callToAction && ctaRenderSwitch(callToAction.type)}</S.CTAWrapper>
  ) : (
    <CtaButtonWithDiscountInfo onClickBecomeAMember={onClickBecomeAMember} />
  );
};

export default CtaButton;
