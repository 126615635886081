import { useHistory } from 'react-router-dom';
import { useGTMDataLayer } from '../../../hooks/GTM/useGTMDataLayer';

export const useBecomeAMemberHandler = () => {
  const dataLayer = useGTMDataLayer();
  const history = useHistory();

  const onClickBecomeAMember = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    dataLayer?.push({
      event: 'cta_click',
      cta_text: 'Bli medlem',
      cta_location: history.location.pathname,
      cta_url: '/medlemskap',
      cta_position: 'content',
    });
    history.push('/medlemskap');
  };

  return onClickBecomeAMember;
};
