import styled from 'styled-components';
import { Text } from '@naf/text';
import { breakpoints, radius, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';

export const BenefitHeaderContainer = styled.div``;

export const ImageWrapper = styled.div`
  position: relative;
  margin: ${spacing.space40} auto 0;
  padding: 0;
  width: 100%;

  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space24};
  }
`;

export const ImageFigure = styled.figure`
  margin: 0;
  position: relative;
  overflow: hidden;
  border-radius: ${radius.l} ${radius.l} 0 0;

  img {
    width: 100%;
    max-height: 512px;
    object-fit: cover;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 70%,
      rgba(255, 255, 255, 1) 90%
    );
  }
`;

export const PartnerlogoWrapper = styled.div`
  position: absolute;
  top: -${spacing.space12};
  left: ${spacing.space16};
  background-color: ${nafColor.signature.white};
  border-radius: ${radius.s};
  padding: ${spacing.space8};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  box-shadow: 0 ${spacing.space4} ${spacing.space8} ${nafColor.signature.black}33;

  img {
    height: ${spacing.space32};
  }

  @media (min-width: ${breakpoints.m}) {
    left: ${spacing.space64};
  }
`;

export const HeaderContentWrapper = styled.div`
  position: relative;
  padding: 0 ${spacing.space64};
  margin-top: -130px;

  @media (max-width: ${breakpoints.l}) {
    margin-top: 0;
    padding: 0;
    margin-top: -60px;
    flex-direction: column;
    gap: ${spacing.space16};
  }
`;

export const StyledHeaderText = styled(Text)`
  max-width: 60%;
  margin: 0;
  margin-bottom: ${spacing.space32};

  @media (max-width: ${breakpoints.l}) {
    max-width: 100%;
    margin-bottom: ${spacing.space12};
    margin-left: ${spacing.space24};
  }

  @media (max-width: 350px) {
    margin-left: 0;
  }
`;

export const IngressWrapper = styled.div``;

export const Ingress = styled(Text)`
  margin-top: 0;
  margin-bottom: ${spacing.space40};
  max-width: 50%;

  @media (max-width: ${breakpoints.m}) {
    font-size: 1.25rem;
    max-width: 90%;
  }
`;

export const RightContentWrapper = styled.div`
  position: absolute;
  right: ${spacing.space64};
  top: 0px;

  @media (max-width: ${breakpoints.l}) {
    position: static;
    margin-left: 0;
    margin-top: ${spacing.space16};
    margin-bottom: ${spacing.space16};
    width: auto;
  }
`;

export const HRLine = styled.hr`
  border: 0;
  border-top: 3px solid ${nafColor.signature.yellow};
  width: 96px;
  margin: ${spacing.space4} 0 ${spacing.space48};
  color: ${nafColor.signature.yellow};
`;
