import React from 'react';
import { CampaignBanner, CampaignBannerColor, CampaignBannerVariant } from '@naf/banner';
import BlockContent from '../../components/block-content/BlockContent';
import { useResponsiveImageSize } from '../../hooks/useResponsiveImageSize';
import useSelector from '../../redux/typedHooks';
import { cloudinaryImage } from '../../utils/imageUrl';
import { Campaign } from '../../../../types/benefitsType';
import { BenefitDiscountCopy } from '../../components/BenefitDiscountCopy/BenefitDiscountCopy';
import * as S from './Styles';

type Props = {
  campaigns: Campaign[];
  handleCtaBlankExternalTracking: any;
};

export const CampaignBannerMapper = ({ campaigns, handleCtaBlankExternalTracking }: Props) => {
  const responsiveImagesSize = useResponsiveImageSize(675);
  const application = useSelector((state) => state.application);

  return campaigns.map((camp) => {
    const imageWidth = responsiveImagesSize ? responsiveImagesSize.width : undefined;
    const imageHeight = responsiveImagesSize ? responsiveImagesSize.height : undefined;
    const campImage =
      (camp.image &&
        camp.image.publicId &&
        imageWidth &&
        cloudinaryImage(camp.image?.publicId, application, imageWidth, imageHeight, undefined, 1.504)) ||
      undefined;
    const { discountCodes, campaignCallToAction } = camp;

    const showCTA =
      (camp.accessDetails?.isAccessibleForUser || camp.type === 'tips') &&
      campaignCallToAction &&
      campaignCallToAction?.buttonText;
    const showDiscountCode = camp.accessDetails?.isAccessibleForUser && discountCodes?.length;

    return (
      <S.BannerWrapper key={camp.id} $restrainWidth={campaigns.length > 2}>
        <CampaignBanner
          label={camp.label}
          title={camp.name}
          color={camp.color || CampaignBannerColor.Dew}
          img={
            camp.image && {
              src: campImage,
              alt: camp.image?.source_altText || '',
            }
          }
          variant={CampaignBannerVariant.Small}
          ctaButton={
            showCTA && {
              text: campaignCallToAction.buttonText,
              href: campaignCallToAction.url,
              onClick: handleCtaBlankExternalTracking(campaignCallToAction),
              blank: true,
            }
          }
          showDiscountCopy={
            showDiscountCode
              ? discountCodes.map((code) => (
                  <S.CampaignDiscountWrapper key={`discountCode-${code.discountCode}`}>
                    <BenefitDiscountCopy label={code.label} discountCode={code.discountCode} variant={code.variant} />
                  </S.CampaignDiscountWrapper>
                ))
              : null
          }
        >
          <BlockContent value={camp.mainText} />
        </CampaignBanner>
      </S.BannerWrapper>
    );
  });
};

export default CampaignBannerMapper;
