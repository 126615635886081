import styled from 'styled-components';
import { Text } from '@naf/text';
import { breakpoints, radius, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';

export const Container = styled.div`
  padding: ${spacing.space24};
  background-color: ${nafColor.primary.dew}E6;
  width: auto;
  max-width: 350px;
  border-radius: ${radius.s};
  backdrop-filter: blur(1px);

  @media (max-width: ${breakpoints.m}) {
    max-width: 310px;
  }
`;

export const Header = styled(Text)`
  margin: 0;
  margin-bottom: ${spacing.space16};
`;

export const Bullets = styled.div`
  ul {
    margin-bottom: ${spacing.space12};

    &:last-of-type {
      margin-bottom: 0;
    }

    li {
      font-weight: 700 !important;
      font-size: 1rem !important;
    }
  }
`;
