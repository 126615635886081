import React, { useEffect } from 'react';
import { Grid, GridCol, GridRow } from '@naf/grid';
import { useDispatch } from 'react-redux';
import { CampaignBannerColor } from '@naf/banner';
import { cloudinaryImage } from '../../utils/imageUrl';
import { useAuth0Token } from '../../hooks/useAuth0Token';
import BlockContent from '../../components/block-content/BlockContent';
import useSelector from '../../redux/typedHooks';
import { actions as membershipActions } from '../../redux/modules/myMembership';
import { LayoutWithMainContent } from '../../components/layout/Layout';
import { BenefitsType } from '../../../../types/benefitsType';
import { DEFAULT_IMG_URL } from '../../lib/constants';
import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import { useWindowLocation } from '../../hooks/Window/useWindowLocation';
import FeedbackSurvey from '../../components/feedbacksurvey/FeedbackSurvey';
import SchemaArticle from '../../components/structured-data/SchemaArticle';
import { LoaderContent } from '../LoaderPage';
import { useDocumentUrl } from '../../hooks/useDocumentUrl';
import CampaignBannerMapper from './CampaignBannerMapper';
import CtaButton from './CtaButton';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';
import { MetaData } from '../../components/metaData/MetaData';
import AppAnnouncement from './AppAnnouncement';
import { useBecomeAMemberHandler } from '../../utils/Tracking/Button/useBecomeAMemberHandler';
import { useCtaBlankExternalTracking } from '../../utils/Tracking/Button/useCtaBlankExternalTracking';
import { BenefitHeader } from './BenefitHeader/BenefitHeader';
import StepByStepList from '../../components/StepByStepList/StepByStepList';
import StA from '../../styles/articles/StyledArticle';
import { BenefitDiscountCopy } from '../../components/BenefitDiscountCopy/BenefitDiscountCopy';
import NewRelatedBenefits from './NewRelatedBenefits/NewRelatedBenefits';
import * as S from './Styles';

interface Props {
  benefitArticle: BenefitsType;
}

export const BenefitArticle = ({ benefitArticle }: Props) => {
  const windowPath = useWindowLocation();
  const { simpleToken } = useAuth0Token();
  const dispatch = useDispatch();
  const application = useSelector((state) => state.application);
  const categoriesState = useSelector((state) => state.sitestructure);
  const {
    meta: { fetchState },
  } = useSelector((state) => state.myMembership.customerInformation);

  const {
    slug,
    title,
    ingress,
    body,
    id,
    video,
    image,
    campaign,
    tips,
    relatedContent,
    meta,
    seoConfig,
    partner,
    category,
    keyInformation,
    callToAction,
    metaDisplay,
    urlParameters,
    displayNafApp,
    stepByStepSection,
    discountCodes,
    accessDetails,
  } = benefitArticle || {};
  const url = useDocumentUrl(slug);
  const onClickBecomeAMember = useBecomeAMemberHandler();
  const handleCtaBlankExternalTracking = useCtaBlankExternalTracking();

  useEffect(() => {
    if (simpleToken && fetchState === 'initial') {
      dispatch(membershipActions.getCustomerInformation.request(simpleToken));
    }
  }, [fetchState, dispatch, simpleToken]);

  if (!id) return null;
  const seoDescription = seoConfig?.introduction ? seoConfig.introduction : ingress;
  const seoTitle = seoConfig?.title ? seoConfig.title : title;
  const mappedCategory = category?.slug ? categoriesState?.mappedCategories[category.slug].data : null;
  const schemaImage = (image && image.publicId && cloudinaryImage(image.publicId, application, 1200, 630)) || undefined;

  const campaignsAndTips = [
    ...campaign.map((c) => ({ ...c, type: 'campaign' })),
    ...tips.map((t) => ({ ...t, color: CampaignBannerColor.Yellow20, type: 'tips' })),
  ];

  return (
    <LayoutWithMainContent
      title={title}
      description={ingress || seoDescription}
      url={windowPath}
      imgUrl={schemaImage || DEFAULT_IMG_URL}
      gtmArgs={{
        page_type: 'BenefitPage',
        pageCategory: `${mappedCategory?.prettyUrl}/${title}`,
        contentId: id,
      }}
      isHiddenFromSearch={seoConfig?.isHiddenFromSearch}
      seoTitle={seoTitle}
      seoDescription={seoDescription}
      internalSearchMetaData={{
        cardType: InternalSearchCardType.Benefit,
        imagePublicId: image?.publicId,
        partnerName: partner?.partnerName,
        partnerLogoPublicId: partner?.logo?.publicId,
      }}
      isMemberBenefitPage
    >
      <S.BenefitWrapper>
        <SchemaArticle
          title={title}
          description={ingress}
          imgUrl={schemaImage}
          datePublished={meta?.publishAt}
          dateModified={meta?.updatedAt}
          url={url}
          id={slug}
        />
        <Grid>
          <GridRow>
            <GridCol s="12" m="12" l="12" xl="12">
              <S.BenefitContent>
                <BreadCrumb />
              </S.BenefitContent>
              <BenefitHeader
                image={image}
                video={video}
                partner={partner}
                title={title}
                ingress={ingress}
                keyInformation={keyInformation}
              />
              <S.BenefitContent>
                <GridRow>
                  <GridCol s="12" m="12" l="8" xl="8">
                    {body ? (
                      <>
                        {stepByStepSection && (
                          <StepByStepList
                            title={stepByStepSection?.title}
                            ingress={stepByStepSection?.ingress}
                            list={stepByStepSection?.list}
                            variant="outline"
                          />
                        )}
                        <S.DiscountWrapper>
                          {discountCodes &&
                            accessDetails?.isAccessibleForUser &&
                            discountCodes.map((code) => (
                              <S.BenefitDiscountCopyWrapper key={`discountCode-${code}`}>
                                <BenefitDiscountCopy
                                  label={code.label}
                                  discountCode={code.discountCode}
                                  variant={code.discountCode}
                                />
                              </S.BenefitDiscountCopyWrapper>
                            ))}
                          <CtaButton
                            callToAction={callToAction}
                            urlParameters={urlParameters}
                            onClickBecomeAMember={onClickBecomeAMember}
                            handleCtaBlankExternalTracking={handleCtaBlankExternalTracking}
                            accessDetails={accessDetails}
                          />
                        </S.DiscountWrapper>
                        <StA.Body>
                          <BlockContent value={body} />
                        </StA.Body>
                        {campaignsAndTips?.length > 0 && (
                          <S.MobileWrapper>
                            <CampaignBannerMapper
                              campaigns={campaignsAndTips}
                              handleCtaBlankExternalTracking={handleCtaBlankExternalTracking}
                            />
                          </S.MobileWrapper>
                        )}
                      </>
                    ) : (
                      <LoaderContent />
                    )}
                    <MetaData meta={meta} metaDisplay={metaDisplay} />
                  </GridCol>
                  {campaignsAndTips?.length > 0 &&
                    (campaignsAndTips.length <= 2 ? (
                      <GridCol s="12" m="12" l="4" xl="4">
                        <S.DesktopWrapper $numberOfBullets={keyInformation?.items?.length}>
                          <CampaignBannerMapper
                            campaigns={campaignsAndTips}
                            handleCtaBlankExternalTracking={handleCtaBlankExternalTracking}
                          />
                        </S.DesktopWrapper>
                      </GridCol>
                    ) : (
                      <GridCol s="12" m="12" l="12" xl="12">
                        <S.DesktopWrapper>
                          <S.CampaignDiscountWrapper>
                            <CampaignBannerMapper
                              campaigns={campaignsAndTips}
                              handleCtaBlankExternalTracking={handleCtaBlankExternalTracking}
                            />
                          </S.CampaignDiscountWrapper>
                        </S.DesktopWrapper>
                      </GridCol>
                    ))}
                </GridRow>
                {displayNafApp?.nafApp && <AppAnnouncement />}
                {relatedContent && relatedContent.length > 0 && <NewRelatedBenefits relatedContent={relatedContent} />}
              </S.BenefitContent>
            </GridCol>
          </GridRow>
          {!metaDisplay?.hideForm && (
            <S.StyledGridRow>
              <GridCol s="12" m="12" l="8" xl="8">
                <FeedbackSurvey contentUrl={windowPath} />
              </GridCol>
            </S.StyledGridRow>
          )}
        </Grid>
      </S.BenefitWrapper>
    </LayoutWithMainContent>
  );
};

export default BenefitArticle;
