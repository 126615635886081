import React, { useState } from 'react';
import * as S from './Styles';

export enum BenefitDiscountCopyVariant {
  Small = 'small',
  Large = 'large',
}

export interface BenefitDiscountCopyProps {
  label: string;
  discountCode?: string;
  variant?: string;
}

const mapVariant = (variant: string): BenefitDiscountCopyVariant => {
  switch (variant) {
    case 'large':
      return BenefitDiscountCopyVariant.Large;
    case 'small':
    default:
      return BenefitDiscountCopyVariant.Small;
  }
};

export const BenefitDiscountCopy = ({
  label,
  discountCode,
  variant = BenefitDiscountCopyVariant.Small,
}: BenefitDiscountCopyProps) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (discountCode) {
      navigator.clipboard.writeText(discountCode).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
      });
    }
  };

  return (
    <S.CopyButton
      onClick={handleCopy}
      $hasCopied={copied}
      $variant={mapVariant(variant)}
      aria-pressed={copied}
      aria-label={copied ? 'Kode kopiert' : `Kopier ${label} til utklippstavlen.`}
    >
      <S.LabelContainer>
        <S.Label>{copied ? 'Kode kopiert' : label}</S.Label>
        <S.CodeAndIconContainer>
          {!copied && <S.DiscountCode>{discountCode}</S.DiscountCode>}
          {copied ? <S.StyledCheck height={24} width={24} /> : <S.StyledFileCopy height={24} width={24} />}
        </S.CodeAndIconContainer>
      </S.LabelContainer>
    </S.CopyButton>
  );
};
