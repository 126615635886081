import React from 'react';
import { TextVariant } from '@naf/text';
import { TextList, TextListItem } from '@naf/text-list';
import * as S from './Styles';

export interface BenefitBulletsCardProps {
  title?: string;
  bullets?: string[];
}

export const BenefitBulletsCard = ({ title, bullets }: BenefitBulletsCardProps) => (
  <S.Container>
    {title && <S.Header variant={TextVariant.Header2}>{title}</S.Header>}
    <S.Bullets>
      {bullets.map((bullet) => (
        <TextList variant="check" key={`bullet-${bullet}`}>
          <TextListItem>{bullet}</TextListItem>
        </TextList>
      ))}
    </S.Bullets>
  </S.Container>
);
